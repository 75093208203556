<template>
    <user-info-layout>
        <template #heading-text>What industry do you work in?</template>
        <v-select
            v-model="industrySelector"
            :menu-props="{bottom: true, offsetY: true}"
            outlined
            :items="options"
            placeholder="Industry"
            @change="handleChange"
        ></v-select>
        <v-form
            ref="form"
            lazy-validation
            v-model="valid"
            @submit.prevent="handleSubmit"
        >
            <v-text-field
                v-if="isOther" 
                v-model="industryText"
                label="Please specify"
                class="mt-4"
                outlined
                :counter="100"
                :rules="rules"
                validate-on-blur
            ></v-text-field>
            <v-btn
                v-if="showNextButton" 
                large
                class="mt-4"
                :width="$vuetify.breakpoint.xs ? '100%' : '25%'"
                :elevation="0"
                color="primary"
                @click="handleSubmit"
            >
                Next
            </v-btn>
        </v-form>
    </user-info-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import UserInfoLayout from '../../layouts/UserInfoLayout.vue';

export default {
    name: "Industry",
    components: {
        UserInfoLayout,
    },
    computed: {
        ...mapGetters({
            industry: 'userInfoForm/industry',
        }),
        industryText: {
            get() {
                return this.industry;
            },
            set(val) {
                this.setIndustry(val);
            }
        },
        industrySelector: {
            get() {
                if(this.isOther) {
                    return 'Other';
                }
                return this.industry;
            },
            set(val) {
                if(val === 'Other') {
                    this.setIndustry(''); 
                } else {
                    this.setIndustry(val);
                }
            }
        },
        isOther() {
            if(this.industry !== null) {
                const slicedOptions = this.options.slice(0, this.options.length-1);
                const item = slicedOptions.find(option => option === this.industry);
                return !item;
            }
            return false;
        },
        showNextButton() {
            return !!this.industry || this.isOther;
        }
    },
    data() {
        return {
            valid: true,
            options: [
                'SaaS',
                'Health Care',
                'Agency',
                'E-Commerce, Retail & Consumer Goods',
                'Professional Services',
                'Education',
                'Consumer Services',
                'Other',
            ],
            rules: [
                value => !!value || 'Required',
                value => (value && value.length <= 100) || "Word limit exceeded",
            ]
        };
    },
    methods: {
        ...mapMutations({
            setIndustry: 'userInfoForm/setIndustry',
        }),
        handleChange() {
            if(this.industrySelector !== 'Other') {
                this.$router.push({ name: 'user-info.organization' });
            }
        },
        handleSubmit() {
            const valid = this.$refs.form.validate();
            if(!valid) {
                return;
            }
            this.$router.push({ name: 'user-info.organization' })
        }
    },
};
</script>

<style scoped lang="scss">
    .v-select {
        width: 65%;
    }
    .v-text-field {
        width: 65%;
    }
</style>
